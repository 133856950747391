import * as React from "react"
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
      <main>
        <h1>Page Not Found</h1>
        <Link to="/">Go home</Link>.
      </main>
  )
}

export const Head = () => (
    <>
        <title>Runic AI</title>
    </>
)

export default NotFoundPage
